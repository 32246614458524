@import 'src/assets/styles/helpers/_mixin';
.banner {
	margin-top: 104px;
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
		text-align: center;
	}
	p {
		margin-top: 4px;
		max-width: 510px;
		text-align: center;
	}
	button {
		margin-top: 44px;
		width: 250px;
		height: 56px;
	}
	img {
		width: 100%;
		margin-top: 44px;
	}
}
.logos {
	height: auto;
	margin-top: 54px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;
	border-radius: 24px;
	box-shadow: 0 4px 4px #00000040;
	padding: 25px 40px 25px 0;

	img {
		max-height: 55px;
		height: 100%;
		width: auto;
	}
	img:nth-child(1) {
		margin-left: 0;
	}
	img:nth-child(2) {
		margin-left: -30px;
	}
	img:nth-child(4) {
		margin-left: -10px;
	}
	img:nth-child(5) {
		margin-left: -10px;
	}
	@include _1024 {
		flex-wrap: wrap;
		justify-content: center;
		gap: 12px;
	}
}
.recommendations {
	margin-top: 73px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;

	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
		text-align: center;
	}
	> div {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 48px;
		@include _744 {
			flex-direction: column;
			align-items: center;
			max-width: 480px;
		}
		> div {
			overflow: hidden;
			flex: 1;
			width: 100%;
			height: 384px;
			text-align: center;
			@include _744 {
				flex: auto;
				height: 420px;
			}
			> div {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
			}
			h3 {
				margin-top: 38px;
				padding: 0;
				max-width: 214px;
				align-self: center;
			}
			p {
				align-self: center;
				margin-top: 10px;
				max-width: 224px;
			}

			img {
				border-radius: 24px;
			}
			button {
				background: #e2e8f0;
				color: black;
				height: 44px;
				width: 127px;
			}
		}
	}
	div:last-child {
		> div {
			width: 336px;
			height: 456px;
			@include _744 {
				width: 480px !important;
			}
		}
	}
	.card1 {
		color: white;
		background: #81848d;
	}
	.card2 {
		background: #e2e8f0;
	}
	.card3 {
		width: 336px;
		height: 456px;
		background: #eaecee;
		img {
			align-self: flex-start;
		}
		@include _744 {
			width: 480px;
		}
	}

	.card4 {
		width: 336px;
		height: 456px;
		background: #fff3db;
		h3 {
			height: 56px;
		}
		img {
			align-self: flex-end;
		}
		@include _744 {
			width: 480px;
		}
	}
	.card5 {
		width: 336px;
		height: 456px;
		background: #c5b0bf;
		img {
			align-self: flex-end;
		}
		@include _744 {
			width: 480px;
		}
	}
	> button {
		width: 144px;
		height: 44px;
		padding: 0;
	}
}
.whyUs {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
		text-align: center;
	}
	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: white;
		border-radius: 24px;
		padding: 32px;
		width: 100%;
		@include _650 {
			flex-direction: column;
		}
		h3 {
			max-width: 461px;
			font-weight: 500;
			font-size: 1.5rem;
			line-height: 1.75rem;
		}
		p {
			max-width: 461px;
			margin-top: 16px;
			font-size: 0.93rem;
			line-height: 1.5rem;
		}
		button {
			margin-top: 24px;
		}
	}
	img {
		margin-right: -20px;
		width: 360px;
	}
}
.services {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;

	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
		text-align: center;
	}
	> div {
		display: flex;
		flex-wrap: wrap;
		gap: 48px;
		justify-content: center;
		@include _744 {
			gap: 20px;
		}
		div {
			background: white;
			border-radius: 24px;
			max-width: 336px;
			height: 304px;
			padding: 24px 32px;
			box-shadow: 0 4px 4px #00000040;
			img {
				width: 52px;
				height: 52px;
			}
			h3 {
				margin-top: 21px;
				font-size: 1.375rem;
				font-weight: 500;
				line-height: 1.75rem;
			}
			p {
				margin-top: 12px;
				font-size: 0.93rem;
				line-height: 1.5rem;
			}
		}
	}
}
.clearing {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
	}
	> p {
		margin-top: 14px;
		max-width: 525px;
	}
	button {
		margin-top: 38px;
		height: 56px;
		width: 175px;
	}
	> img {
		width: 100%;
		margin-top: 72px;
	}
	> div {
		display: flex;
		justify-content: space-between;
		gap: 48px;
		@include _1024 {
			flex-direction: column;
		}
		div {
			background: white;
			border-radius: 24px;
			height: 209px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 528px;
			@include _480 {
				width: 100%;
			}
			p {
				max-width: 420px;
				text-align: left;
				font-size: 2.06rem;
				line-height: 2.5rem;
				@include _480 {
					font-size: 1.5rem;
				}
			}
			img {
				margin-bottom: 28px;
				margin-left: -40px;
				align-self: flex-end;
			}
		}
		div:first-child {
			span {
				color: #fdae00;
			}
		}
		div:last-child {
			span {
				color: #6e82e6;
			}
		}
	}
}

.howItWorks {
	margin-top: 164px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
		text-align: center;
		margin-bottom: 28px;
	}
	img:first-child {
		margin-left: 120px;
		@include _744 {
			display: none;
		}
	}
	img:last-child {
		max-width: 700px;
		margin-bottom: -60px;
		@include _1015 {
			width: 100%;
		}
	}
	.bottomContainer {
		margin-top: 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		> div {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			@include _744 {
				margin-bottom: 20px;
				margin-top: 20px;
				flex-direction: column !important;
			}
		}
		@include _744 {
			flex-direction: column;
		}
	}
	.card {
		margin-top: 60px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 48px;
		text-align: center;
		@include _1100 {
			justify-content: center;
		}
		> div {
			width: 336px;
			height: 150px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: 1px solid #000000;
			background: white;
			border-radius: 24px;
			position: relative;
			@include _480 {
				width: 250px;
				height: 200px !important;
			}
			.text {
				padding-top: 16px;
				max-width: 275px;
				font-size: 0.95rem;
				line-height: 1.5rem;
			}
		}
		.index {
			background: var(--accent);
			color: white;
			position: absolute;
			top: -30px;
			border-radius: 18px;
			height: 56px;
			width: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.5rem;
			font-weight: 500;
		}
	}
}
.mobileApp {
	width: 100%;
	margin-top: 104px;
	display: flex;
	justify-content: space-between;
	background: #e2e8f0;
	border-radius: 24px;
	padding: 40px 63px;
	gap: 230px;
	> img {
		margin-bottom: -40px;
		margin-left: 117px;
		@include _1200 {
			margin-left: 0;
		}
		@include _744 {
			width: 40%;
			height: auto;
			margin-bottom: -20px;
		}
		@include _650 {
			width: 60%;
		}
		@include _480 {
			width: 90%;
		}
	}
	@include _900 {
		gap: 20px;
	}
	@include _744 {
		padding: 20px;
		gap: 0;
	}
	@include _650 {
		flex-direction: column-reverse;
		align-items: center;
		gap: 20px;
		padding: 20px;
	}
	> div {
		width: 330px;
		@include _744 {
			padding: 20px;
		}
		div:first-child {
			background: white;
			border-radius: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 120px;
			height: 120px;
		}
		h3 {
			margin-top: 64px;
			font-size: 2.06rem;
			line-height: 2.5rem;
		}
		p {
			font-size: 0.93rem;
			line-height: 1.5rem;
			margin-top: 12px;
		}
		div:last-child {
			margin-top: 44px;
			display: flex;
			gap: 12px;
		}
	}
}
.actual {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
		text-align: center;
	}
	.mainActual {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #eaecee;
		border-radius: 24px;
		width: 100%;
		padding-top: 32px;
		padding-left: 56px;
		@include _744 {
			flex-direction: column;
			padding: 20px;
			justify-content: center;
		}
		div {
			max-width: 480px;
			h3 {
				font-size: 2.1875rem;
				line-height: 2.5rem;
			}
			p {
				margin-top: 10px;
				font-size: 0.93rem;
				line-height: 1.5rem;
			}
			button {
				width: 127px;
				height: 44px;
				margin-top: 90px;
				align-self: flex-start;
			}
		}
		img {
			margin-right: 10px;

			@include _480 {
				width: 100%;
			}
		}
	}
	.actualCardContainer {
		display: flex;
		justify-content: space-between;
		gap: 48px;
		width: 100%;
		@include _744 {
			flex-wrap: wrap;
			justify-content: center;
		}
		div {
			padding: 32px;
			background: white;
			border-radius: 24px;
			max-width: 336px;
			height: auto;
			display: flex;
			flex-direction: column;
			gap: 32px;
			h3 {
				font-weight: 500;
				font-size: 1.5rem;
				line-height: 1.75rem;
			}
			p {
				margin-top: -16px;
				font-size: 0.93rem;
				line-height: 1.5rem;
			}

			a {
				text-decoration: none;
				color: var(--link);
			}
			a:hover {
				text-decoration: underline;
			}
		}
	}
}
.partnerShip {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
		text-align: center;
	}
	> div {
		padding-top: 52px;
		padding-bottom: 33px;
		width: 100%;
		padding-left: 66px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: white;
		border-radius: 24px;
		box-shadow: 0 4px 4px #00000040;
		@include _900 {
			flex-direction: column;
			padding: 40px;
		}
		img {
			width: 100%;
		}
		div {
			max-width: 467px;
			h3 {
				font-weight: 500;
				font-size: 2.06rem;
				line-height: 1.75rem;
			}
			p {
				margin-top: 21px;
				font-size: 1.5rem;
				line-height: 2.06rem;
				color: #000000cc;
				font-weight: 400;
			}
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			button {
				margin-top: 49px;
				align-self: flex-start;
			}
		}
	}
}
.forPartners {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 48px;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
	}
	> div {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 48px;
		div {
			p {
				max-width: 214px;
				margin-top: 10px;
			}
		}
		button {
			height: 44px;
			width: 129px;
		}
	}
	.card6 {
		background: #eaecee;
	}
	.card7 {
		background: #fff3db;
	}
	.card8 {
		background: #81848d;
		color: white;
		img {
			align-self: flex-end;
		}
	}
	.card9 {
		background: #c5b0bf;
	}
}
.faq {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	> div {
		margin-top: 56px;
		display: flex;
		@include _1015 {
			flex-direction: column;
		}
		> img {
			width: 100%;
			height: 677px;
			@include _1015 {
				display: none;
			}
		}
	}
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 700;
	}
	.accordionContainer {
		background: white;
		border-radius: 24px;
		text-align: left;
		h3 {
			color: #000000;
			font-size: 1.375rem;
			line-height: 1.875rem;
			font-weight: 400;
			margin: 0;
			padding: 0 33px;
			text-align: left;
		}
		p {
			padding: 0 30px;
		}
		div {
			box-shadow: none;
			border-top-left-radius: 24px;
			border-top-right-radius: 24px;
		}
		div:last-child {
			border-bottom-left-radius: 24px;
			border-bottom-right-radius: 24px;
		}
	}
}
.question {
	display: flex;
	justify-content: space-between;
	max-width: 912px;
	align-self: center;
	h3 {
		font-weight: 500;
		font-size: 1.375rem;
		line-height: 1.75rem;
	}
}
.findAnswer {
	margin-top: 73px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;
	border-radius: 24px;
	height: 108px;
	padding: 40px 32px;
	@include _480 {
		flex-direction: column;
		justify-content: center;
		gap: 12px;
	}
	h3 {
		font-weight: 500;
		font-size: 1.375rem;
	}
	div {
		display: flex;
		gap: 8px;
		button {
			color: #7659a1;
			background: #ecf1f7;
			border: none;
			width: 97px;
			height: 44px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			font-size: 0.93rem;
			cursor: pointer;
			transition: 0.3s;
		}
		button:hover {
			background: #ececec;
		}
		button:first-child {
			img {
				margin-top: 5px;
			}
		}
	}
}
.missing {
	align-self: flex-start;
	max-width: 883px;
	margin-top: 94px;
	border-radius: 24px;
	background: white;
	padding: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h1 {
		font-weight: 500;
		font-size: 1.375rem;
		line-height: 1.75rem;
	}
	button {
		background: transparent;
		outline: 1px solid var(--link);
		color: var(--link);
	}
	button:hover {
		background: var(--link);
		outline-color: var(--link);
		color: white;
	}
	button:active {
		background: #2e62d2;
		outline-color: var(--link);
		color: white;
	}
}
.innerPadding {
	width: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column !important;
	align-items: flex-start;
	border: 1px solid #eaecee;
	border-radius: 20px;
	flex: 1;
	form {
		width: 100%;
	}
	textarea {
		display: flex;
		flex: 1;
		margin-top: 16px;
		width: 100%;
		height: 147px;
		background: #e2e8f0;
		font-size: 0.625rem;
		border: none;
		resize: none;
		padding: 7px 14px;
	}
	button {
		transition: 0.2s;
		background: var(--accent);
		outline: none;
		color: white;
		align-self: flex-end;
		margin-right: 20px;
		margin-top: 16px;
	}
}

.activeMissing {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
