.logo{
  display: flex;
  align-items: center;
  h1{
    font-size:0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
  img{
    height:42px;
    width:auto;
  }
}