@import 'src/assets/styles/helpers/_mixin';

.banner {
	margin-top: 76px;
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		max-width: 100%;
	}
}
.bannerHeading {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 564px;
	text-align: center;
	gap: 7px;
	h1 {
		font-weight: 500;
	}
	button {
		margin-top: 26px;
		width: 241px;
		height: 56px;
	}
	a {
		text-decoration: none;
	}
}
.logos {
	width: 100%;
	margin-top: 25px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 24px;
	height: 172px;
	padding: 0 45px;
	@include _744 {
		flex-direction: row;
		justify-content: center;
	}
	img {
		height: auto;
		width: auto;
	}
	div:first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include _744 {
			flex-direction: column;
			gap: 15px;
		}
	}
	div:last-child {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-top: -15px;
		margin-left: 90px;
		margin-right: 30px;
		@include _744 {
			flex-direction: column;
			gap: 15px;
		}
	}
}
.bannerCardsContainer {
	margin-top: 75px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 48px;
	> div {
		width: 336px;
		height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #000000;
		background: white;
		border-radius: 24px;
		position: relative;
		@include _480 {
			width: 250px;
			height: 200px !important;
		}
		.text {
			padding-top: 50px;
			max-width: 275px;
			font-size: 0.9rem;
			line-height: 1.5rem;
			text-align: center;
		}
	}
	> div:nth-child(n-3) {
		margin-top: 25px;
	}
	.index {
		background: var(--accent);
		color: white;
		position: absolute;
		top: -30px;
		border-radius: 18px;
		height: 56px;
		width: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.5rem;
		font-weight: 500;
	}
}
.paymentService {
	margin-top: 107px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	.paymentServiceHeading {
		max-width: 443px;
		text-align: center;
		font-weight: 500;
	}
	.services {
		padding: 63px 140px;
		margin-top: 52px;
		width: 100%;
		border-radius: 24px;
		background: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include _744 {
			padding: 20px 60px;
		}
	}
	.findService {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		gap: 20px;
		h3 {
			font-weight: 500;
			font-size: 1.37rem;
		}
		> div {
			display: flex;
			gap: 20px;
			@include _480 {
				flex-direction: column;
			}
		}
		.serviceCard {
			padding: 7px 16px;
			border: 1px solid #dddfe0;
			border-radius: 4px;
			width: 258px;
			h6 {
				color: #000000cc;
				font-size: 0.875rem;
			}
			p {
				margin-top: -5px;
				color: #0000008a;
				line-height: 1rem;
				font-size: 0.75rem;
			}
		}
	}

	.payService {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		width: 100%;
		.payServiceContainer {
			width: 100%;
			display: flex;
			justify-content: space-between;
			gap: 12px;
			flex-wrap: wrap;
			@include _1015 {
				justify-content: center;
				gap: 48px;
			}
			.payServiceCard {
				box-shadow: 0 1px 3px #dddfe0;
				border: 1px solid #dddfe0;
				padding: 19px 16px;
				border-radius: 16px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				height: 430px;
				max-width: 240px;
				transition: 0.3s;
				img {
					height: 140px;
					width: 100%;
				}
				h1 {
					font-size: 1.3rem;
					line-height: 1.5rem;
					font-weight: 500;
				}
			}
		}
		.payServiceCard:last-child {
			height: 400px;
		}
		.activeCard {
			height: 455px !important;
		}
		.nav {
			margin-top: 36px;
			display: flex;
			gap: 24px;
			align-self: center;
			div {
				transition: 0.5s;
				border-radius: 8px;
				width: 8px;
				height: 8px;
				background: #ecf1f7;
			}
		}
		.activeNav {
			background: #3f157c !important;
		}
		> button {
			margin-top: 15px;
			align-self: flex-end;
			height: 28px;
			width: 84px;
			border-radius: 10px;
			@include _744 {
				margin-top: 25px;
				align-self: center;
			}
		}
	}
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: rgba(0, 0, 0, 0.3);
	z-index: 6;
	.modalHead {
		padding: 11px 41px;
		width: 618px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #7659a1;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		h1 {
			color: white;
			font-size: 1.875rem;
			font-weight: 400;
		}
	}
	.modalBody {
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		height: 600px;
		padding: 28px 86px 48px;
		background: white;
		width: 618px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p {
			margin-top: 20px;
			font-weight: 400;
			font-size: 1rem;
		}

		.modalForm {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 20px;
			> button:last-child {
				align-self: flex-end !important;
			}
			> div {
			}
		}

		input {
			margin-top: 20px;
			height: 38px;
			border: 1px solid #eaecee;
			width: 100%;
			padding-left: 25px;
			border-radius: 5px;
			font-size: 1rem;
		}
		.inputContainer {
			display: flex;
			gap: 20px;
		}
		.input::-moz-placeholder {
			color: #eaecee;
		}
		.input::-webkit-input-placeholder {
			color: #eaecee;
		}

		.upload {
			margin-top: 20px;
			color: black;
			height: 60px;

			label {
				display: flex;
				flex-direction: column;
				button {
					margin: 0;
					padding: 9px;
					color: black;
					border: none;
					background: #e2e8f0;
					width: 215px;
				}
			}

			input {
				border: none;
				display: none;
			}
		}
	}
}
.cross {
	background: transparent;
	border: none;
	cursor: pointer;
	width: 40px;
	height: 30px;
	div {
		background: white;
		width: 100%;
		height: 2px;
	}
	> div {
		margin-top: -2px;
		transform: rotateZ(-45deg);
	}
	div:last-child {
		transform: rotateZ(45deg);
	}
}
.autoPayment {
	margin-top: 48px;
	display: flex;
	justify-content: space-between;
	padding: 40px 48px;
	background: #e2e8f0;
	border-radius: 24px;
	position: relative;
	height: 328px;
	@include _744 {
		flex-direction: column;
		height: auto;
	}
	> div {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 380px;
		h1 {
			font-size: 2.06rem;
			line-height: 2.5rem;
			font-weight: 500;
		}
		p {
			padding-top: 12px;
			font-size: 0.9rem;
		}
		button {
			align-self: flex-start;
			background: white;
			outline: none;
			color: black;
			border-radius: 4px;
		}
		button:hover {
			outline: none;
			color: white;
			background: var(--accent);
		}
	}
	img {
		position: absolute;
		right: 0;
		top: 0;
		height: 302px;
		width: auto;
		@include _1024 {
			right: -85px;
		}
		@include _744 {
			position: static;
		}
	}
}

.clearing {
	margin-top: 76px;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 28px;

	> h1 {
		font-weight: 500;
		text-align: center;
	}
	> p {
		text-align: center;
		max-width: 532px;
	}
}
.clearingCardsContainer {
	margin-top: 52px;
	display: flex;
	justify-content: space-between;
	gap: 48px;
	@include _744 {
		flex-direction: column;
	}
}
.clearingCard {
	background: #ffffff;
	border-radius: 24px;
	padding: 26px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	img {
		width: 112px;
		height: 112px;
	}
	p {
		font-size: 0.875rem;
		color: #000000cc;
	}
	button {
		align-self: flex-start;
		margin-top: 20px;
		outline: 1px solid var(--link);
		color: var(--link);
		background: transparent;
	}
	button:hover {
		outline: none;
		color: white;
		background: var(--accent);
	}
	.cardHead {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h1 {
			font-weight: 500;
			font-size: 1.375rem;
		}
	}
}
