.button{
  display:flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  align-self: center;
  color:white;
  font-size: 0.95rem;
  line-height: 1.5rem;
  padding:  9px  24px  11px  24px;
  background: var(--accent);
  transition: 0.5s;
  cursor: pointer;
}
.button:hover{
  background: white;
  color:var(--accent);
  outline: 1px solid var(--accent);
}
.button:active{
  background: #eeeeee;
  transition: 0.1s;
}