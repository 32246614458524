@import 'src/assets/styles/helpers/_mixin';
.banner {
	margin-top: 76px;
	display: flex;
	flex-direction: column;
	text-align: center;
	h1 {
		font-weight: 500;
	}
	.bannerContainer {
		margin-top: 70px;
		border-radius: 24px;
		display: flex;
		background: #b8d5ff;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		@include _650 {
			flex-direction: column;
			padding: 20px;
		}
		div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			gap: 10px;
			margin-left: 70px;
			@include _650 {
				text-align: center;
				margin-left: 0;
			}
			h3 {
				font-size: 1.875rem;
				font-weight: 500;
				line-height: 2.375rem;
			}
			p {
				font-size: 0.875rem;
				line-height: 1.25rem;
			}
		}
		img {
			border-radius: 24px;
			width: 100%;
		}
	}
}
