// Font settings

/*  Desktop  */
$header: 2.5rem;
$header-height: 3.25rem;

$title: 1.5rem;
$title-height: 1.95rem;

$sub-title: 1.125rem;
$sub-title-height: 1.6875rem;

$default: 1rem;
$default-height: 1.7rem;

$text-small: 0.875rem;
$text-small-height: 1.3125rem;

/*  Mobile  */
$header-mob: 2rem;
$header-mob-height: 2.6rem;

$title-mob: 1.375rem;
$title-mob-height: 1.75rem;