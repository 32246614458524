:root {
	--accent: #7659a1;
	--text: #333333;
	--link: #336fee;
	--accent-light: #eef8f2;
	--accent-transparent: #c1e3cd;
	--white: #ffffff;
	--dark: #5d6963;
	--gray: #b4b4b9;
	--gray-light: #f2f2f2;
	--gray-transparent: rgba(30, 27, 24, 0.61);
	--dark-green: #5d6963;
	--black: #1e1e1e;
	--error: #ec6f68;
	--error-light: #ffe9e8;
	--body-font-family: 'Roboto', sans-serif;
	--accent-font-family: 'Oswald', sans-serif;
	--body-font-size: 1rem;
	--body-line-height: 1.7rem;
	--body-font-weight: 400;
}
