@import 'src/assets/styles/helpers/_mixin';
.banner {
	margin-top: 76px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 500;
	}
	p {
		margin-top: 30px;
		font-size: 0.875rem;
	}
	gap: 70px;
	img {
		width: 100%;
		@include _744 {
			max-width: 528px;
		}
	}
}
.cardContainer {
	display: flex;
	flex-direction: column;
	gap: 48px;
	margin-top: 48px;
	> div {
		display: flex;
		gap: 48px;
		@include _744 {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	@include _1250 {
		padding: 20px;
	}
}
.card {
	border-radius: 24px;
	width: 528px;
	height: 384px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	position: relative;
	transition: 0.3s;
	box-shadow: 0 4px 4px #00000040;
	@include _650 {
		width: 480px;
	}
	@include _480 {
		width: 95%;
	}
	h3 {
		max-width: 332px;
		padding-top: 40px;
		font-size: 1.375rem;
		line-height: 1.75rem;
		font-weight: 500;
		z-index: 2;
	}
	p {
		max-width: 332px;
		font-size: 0.875rem;
		line-height: 1.5rem;
		z-index: 2;
	}
	button {
		margin-bottom: 30px;
		z-index: 2;
	}
	img {
		position: absolute;
		bottom: 0;
		max-width: 100%;
		z-index: 0;
	}
}
.card:hover {
	transform: scale(1.01);
}
.card1 {
	background: #eaebed;
}
.card2 {
	color: white;
	background: #9c99db;
	img {
		bottom: 15px;
		right: 0;
	}
}
.card3 {
	background: #fff3db;
	img {
		left: 0;
		height: 70%;
	}
}

.card4 {
	color: white;
	background: #b58dd6;
	img {
		border-radius: 24px;
	}
}
.partners {
	width: 100%;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 500;
	}
	> div {
		width: 100%;
		margin-top: 48px;
		padding: 0 32px;
		background: white;
		border-radius: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include _744 {
			flex-direction: column-reverse;
		}
		div {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			h3 {
				font-size: 1.375rem;
				line-height: 1.75rem;
				font-weight: 500;
			}
			a {
				margin-top: 4px;
				font-size: 0.875rem;
				color: black;
			}
			img {
				margin-left: -15px;
				width: 543px;
				@include _900 {
					width: 100%;
				}
			}
			button {
				margin-top: 12px;
				align-self: flex-start;
			}
		}
		img {
			width: 100%;
			@include _900 {
				width: 60%;
			}
		}
	}
}
