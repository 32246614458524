@import "src/assets/styles/helpers/mixin";
.header{
  background:white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper{
  padding:0 16px;
  max-width: 1104px;
  display: flex;
  flex-direction: column;
  @include _860{
    display: none;
  }
  > div{
    display: flex;
    align-items: center;
  }

  ul{
    display: flex;
    justify-content: space-between;
    gap:41px;
    li{
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
    a{
      text-decoration: none;
      color: rgba(0, 0, 0, 0.8);

    }
    a:hover{
      text-decoration: underline;
      color: rgb(0, 0, 0);
    }
  }
  .siteNavigation{
    padding-top: 24px;
    justify-content: space-between;
  }
  .otherNavigation{
    border-top:1px solid #DDDFE0;
    margin-top: 22px;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 100px;
    justify-content: flex-end;
    gap:38px;
  }
  .search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.75rem;
    line-height: 1.25rem;

    img{
      width:auto ;
      height: 18px;
    }
  }

  button{
    font-size: 0.75rem;
    line-height: 1.25rem;
    border:none;
    background: transparent;
  }
  .language{
    color: rgba(0, 0, 0, 0.8);
  }
  .login{
    color:#336FEE;
  }
}
.currency{
  color:#0000008A;
  gap:21px;
  span{
    color:#388EC38A;
  }
}
.mobile{
  padding:20px;
  display: none;
  @include _860{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.logos{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:36px ;
}
.active{
  opacity: 1 !important;
  display: flex !important;
  flex-direction: column;
  padding: 50px;
  flex: 1;
}
.mobileMenu{
  z-index: 2;
  display: none ;
  top:0;
  right:0;
  opacity: 0;
  transition: 1s;
  background: #333238;
  position: absolute;
  width:250px;
  padding-top:50px;

}