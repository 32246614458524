.mobileMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: var(--gray-transparent);
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.visible {
    pointer-events: all;
    opacity: 1;
  }
}

.mobileMenu {
  background: var(--white);
  width: 360px;
  height: 100vh;
  padding: 90px 24px;
  overflow-y:scroll;
  overflow-x:hidden;
  transition: .4s ease-in-out;
  transform: translateX(-100%);
  font-size: 1.4rem;
  line-height: 2rem;
  a{
    margin-top:20px;
    color:var(--text);
    text-decoration: none;
  }
  &.visible {
    transform: translateX(0%);
  }
}
.currency{
  color:#0000008A;
  gap:21px;
  span{
    color:#388EC38A;
  }
}
.accordionButton {
  padding: 12px 0;
  font-weight: 600;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    transition: 0.5s;
  }

  &[aria-expanded='true']::before {
    transform: translateY(-50%) rotate(180deg);
  }
}

.accordionElement {
  padding: 12px 0;
  display: flex;
  gap: 10px;
  text-decoration: none;
  color: var(--black);
  font-weight: 500;
}


.link {
  color: var(--black);
  font-weight: 600;
  text-decoration: none;
  display: block;
  padding: 12px 0;
}

.icon {
  svg {
    width: 27px;
    height: 27px;
  }
}
.search{
  display: flex;
  img{
    height: 42px;
     width: auto;
  }
}