@import 'src/assets/styles/helpers/_mixin';
.container {
	margin-top: 56px;
	padding: 20px;
	h1 {
		line-height: 3rem;
		font-size: 2.625rem;
		font-weight: 700;
		max-width: 807px;
	}
	.date {
		margin-top: 57px;
		font-size: 0.875rem;
		line-height: 1.5rem;
		color: #0000008a;
	}
	div {
		border-radius: 24px;
		margin-top: 48px;
		background: white;
		padding: 41px 91px;
		@include _744 {
			padding: 20px;
		}
		p {
			line-height: 1.93rem;
			font-size: 1.25rem;
			color: #000000cc;
		}
	}
}
