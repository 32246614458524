@import 'src/assets/styles/helpers/_mixin';
.notFound {
	margin-top: 80px;
	padding: 20px 22px 40px 47px;
	width: 100%;
	border-radius: 20px;
	box-shadow: 0 4px 12px #00000040;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.border {
		left: 30px;
		bottom: 20px;
		position: absolute;
		width: 533px;
		height: 1px;
	}
	.notFoundBanner {
		align-self: flex-start;
		width: 533px;
		background: #f6f7f8;
		border-radius: 16px;
		display: flex;
		justify-content: space-between;
		padding: 8px 20px;
		@include _900 {
			width: 100%;
		}
		@include _650 {
			flex-direction: column;
		}
		h1 {
			font-size: 1.1rem;
			line-height: 1.5rem;
		}
		.settings {
			gap: 10px;
			display: flex;
			align-items: center;
			img {
				width: 40px;
				height: 40px;
			}
		}
		.try {
			margin-top: 10px;
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			align-items: flex-end;
			div {
				width: 22px;
				height: 22px;
				border-radius: 50%;
				background: #eaecee;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 50%;
				}
			}
		}
	}
	.formContainer {
		margin-top: 11px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		@include _744 {
			flex-direction: column;
		}
		form {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			input {
				border-radius: 4px;
				border: none;
				background: #ecf1f7;
				height: 54px;
				padding: 15px 27px;
				@include _650 {
					margin-top: 8px;
				}
			}
			> div {
				border-radius: 4px;
				padding: 8px;
				border: 1px solid #e7e8ea;
				width: 80%;
				@include _650 {
					margin-top: 8px;
					width: 100%;
				}
				p {
					line-height: 1.2rem;
					font-size: 0.9rem;
					color: black;
				}
			}
		}
		> div {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
			@include _650 {
				align-items: center;
			}
		}
		button {
			width: 100%;
			height: 64px;
			margin-top: 20px;
			align-self: flex-end;
		}
	}
}
