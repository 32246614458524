@import 'src/assets/styles/helpers/mixin';

///////////////   Banner   ///////////////////
.banner {
	max-width: 100%;
	margin-top: 73px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	.heading {
		flex: 1;
		font-weight: 500;
		font-size: 2.625rem;
		line-height: 3rem;
	}
	.subHeading {
		padding-top: 25px;
		margin: auto;
		max-width: 596px;

		font-size: 0.95rem;
		line-height: 1.5rem;
		color: var(--text);
	}
	img {
		max-width: 100%;
		padding-top: 20px;
	}
}
.bannerCardsContainer {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 48px;
	> div {
		width: 336px;
		height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #000000;
		background: white;
		border-radius: 24px;
		position: relative;
		@include _480 {
			margin-top: 20px;
			width: 250px;
		}
		.text {
			padding-top: 44px;
			max-width: 290px;
			font-size: 0.875rem;
			line-height: 1.25rem;
		}
	}

	.index {
		background: var(--accent);
		color: white;
		position: absolute;
		top: -30px;
		border-radius: 18px;
		height: 56px;
		width: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.5rem;
		font-weight: 500;
	}
}

///////////////   Replenishment   ///////////////////

.replenishment {
	margin-top: 48px;
	background: #fff3db;
	border-radius: 24px;
	display: flex;
	justify-content: space-between;
	padding: 26px 32px;
	color: var(--text);
	@include _650 {
		flex-direction: column;
		text-align: center;
		gap: 15px;
	}
	h1 {
		font-size: 1.375rem;
		line-height: 1.75rem;
		font-weight: 500;
	}
	p {
		font-size: 0.95rem;
		line-height: 1.5rem;
	}
	button {
		margin-right: 54px;
		@include _744 {
			margin-right: 0;
		}
	}
}

///////////////   Cards   ///////////////////

.cards {
	margin-top: 100px;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	gap: 48px;
	align-items: center;
	justify-content: center;
	padding: 15px;

	> h1 {
		font-size: 2.6875rem;
		line-height: 3rem;
		font-weight: 500;
		color: var(--text);
	}
}
.card {
	transition: 0.5s;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;
	border-radius: 24px;
	padding: 32px 0 32px 32px;
	@include _744 {
		flex-direction: column;
		gap: 40px;
	}
	.cardPhoto {
		max-height: 100%;
		border-radius: 28px;
		padding-right: 18px;
		width: auto;
	}
}
.card:hover {
	transition: 0.5s;
	scale: 1.01;
}
.cardData {
	display: flex;
	flex-direction: column;
	gap: 40px;
	@include _744 {
		width: 90%;
	}
	.title {
		font-size: 1.375rem;
		line-height: 1.75rem;
		font-weight: 500;
		color: var(--text);
	}
	.description {
		padding-top: 11px;
		font-size: 0.875rem;
		line-height: 1.5rem;
	}
}
.cardType {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px 20px 8px 12px;
	background: #ecf1f7;
	border-radius: 8px;
	font-size: 0.75rem;
	max-width: 230px;
	img {
		height: 24px;
		width: 24px;
	}
}
.cardConditions {
	padding-top: 40px;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	gap: 30px;
	.conditionTitle {
		font-size: 1.125rem;
		line-height: 1.5rem;
		font-weight: 500;
	}
	.conditionDescription {
		font-size: 0.8rem;
		line-height: 1.25rem;
	}
}
.getCard {
	padding-top: 75px;
	display: flex;
	align-items: center;
	gap: 56px;
	@include _480 {
		gap: 40px;
	}
	a {
		font-size: 0.95rem;
		line-height: 1.5rem;
		color: var(--link);
		text-decoration: none;
	}
	a:hover {
		text-decoration: underline;
	}
}
.modal {
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.11);
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modalBody {
	width: 615px;
	background: white;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	font-size: 1.43rem;
	line-height: 1.875rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	h1 {
		color: #9299a2;
	}
	@include _650 {
		width: 500px;
	}
	@include _480 {
		width: 450px;
	}
	@include _420 {
		width: 400px;
	}
	p {
		padding-top: 44px;
		width: 392px;
	}
	ul {
		padding-top: 8px;
		padding-left: 30px;
		list-style: disc;
	}
	button {
		align-self: flex-end;
		margin-right: 40px;
		margin-top: 60px;
		margin-bottom: 40px;
	}
	.buttonContainer {
		display: flex;
		justify-content: flex-end;
		align-self: flex-end;
	}
}
.modalForm {
	padding: 40px 40px 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
	input {
		height: 38px;
		border: 1px solid #eaecee;
		width: 100%;
		padding-left: 25px;
		border-radius: 5px;
		font-size: 1rem;
	}

	.inputContainer {
		display: flex;
		gap: 20px;
	}
	.input::-moz-placeholder {
		color: #eaecee;
	}
	.input::-webkit-input-placeholder {
		color: #eaecee;
	}
	.download {
		padding-left: 15px;
		p {
			font-size: 1rem;
		}
		a {
			font-size: 1.37rem;
			color: var(--link);
			text-decoration: none;
		}
		a:hover {
			text-decoration: underline;
		}
	}
	.upload {
		position: relative;
		font-size: 1rem;
		color: black;
		max-width: 200px;
		height: 60px;
		padding-left: 15px;
		margin-top: 30px;

		label {
			button {
				cursor: pointer;
				margin: 0;
				padding: 9px;
				color: black;
				border: none;
				background: #e2e8f0;
			}
		}
		input {
			top: 0;
			left: -20px;
			cursor: pointer;
			position: absolute;
			border: none;
			width: 100%;
			height: 130%;
			opacity: 0;
		}
	}
}
.modalHeader {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background: #7659a1;

	display: flex;
	flex-direction: column;
	padding: 29px 32px;
	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	h1 {
		color: white;
		font-size: 1.875rem;
	}
	.cross {
		cursor: pointer;
		width: 35px;
		height: 35px;
		padding-top: 15px;
		div {
			background: white;
			width: 100%;
			height: 2px;
			margin-top: 15px;
		}
		> div {
			transform: rotateZ(-45deg);
			margin-top: -1px;
		}
		div:last-child {
			transform: rotateZ(45deg);
		}
	}
}
.nav {
	margin-top: 16px;
	position: relative;
	width: 100%;
	ul {
		width: 100%;
		display: flex;
		justify-content: space-between;
		li {
			z-index: 2;
			border-radius: 8px;
			border: 1px solid white;
			height: 40px;
			width: 40px;
			background: #7659a1;
			color: #a5a3a3;
			font-size: 1.43rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.done {
			border: none;
			color: white;
		}
	}
}
.border {
	position: absolute;
	background: white;
	width: 105%;
	left: -3%;
	height: 1px;
}
///////////////   Missing   ///////////////////

.missing {
	margin-top: 72px;
	border-radius: 24px;
	background: white;
	padding: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h1 {
		font-weight: 500;
		font-size: 1.375rem;
		line-height: 1.75rem;
	}
	button {
		background: transparent;
		outline: 1px solid var(--link);
		color: var(--link);
	}
	button:hover {
		background: var(--link);
		outline-color: var(--link);
		color: white;
	}
	button:active {
		background: #2e62d2;
		outline-color: var(--link);
		color: white;
	}
}
.innerPadding {
	width: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column !important;
	align-items: flex-start;
	border: 1px solid #eaecee;
	border-radius: 20px;
	flex: 1;
	form {
		width: 100%;
	}
	textarea {
		display: flex;
		flex: 1;
		margin-top: 16px;
		width: 100%;
		height: 147px;
		background: #e2e8f0;
		font-size: 0.875rem;
		border: none;
		resize: none;
		padding: 7px 14px;
	}
	button {
		transition: 0.2s;
		background: var(--accent);
		outline: none;
		color: white;
		align-self: flex-end;
		margin-right: 20px;
		margin-top: 16px;
	}
}

.activeMissing {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
///////////////  Faq  ///////////////////

.faq {
	> h1 {
		margin-top: 100px;
		text-align: center;
		font-weight: 500;
	}
	.accordionContainer {
		margin-top: 48px;
		background: white;
		border-radius: 24px;
		align-self: center;
		padding: 24px 0;
		h3 {
			color: #333333;
			font-size: 1.375rem;
			font-weight: 500;
			margin: 0;
		}
		div {
			align-self: center;
			box-shadow: none;
			border-top-left-radius: 24px;
			border-top-right-radius: 24px;
		}
		div:last-child {
			align-self: center;
			border-bottom-left-radius: 24px;
			border-bottom-right-radius: 24px;
		}
	}
}
.question {
	align-self: center;
	display: flex;
	justify-content: space-between;
	width: 912px;
	h3 {
		padding-bottom: 13px;
		padding-top: 17px;
		font-weight: 500;
		font-size: 1.375rem;
		line-height: 1.75rem;
	}
}
