@import 'src/assets/styles/helpers/mixin';

.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 176px;

	.logos {
		width: 100%;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 17px 0;
		.logosWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1104px;
			padding: 0 16px;
			@include _650 {
				flex-direction: column;
				gap: 10px;
			}
			ul {
				display: flex;
			}
			.info {
				display: flex;
				align-items: center;
				gap: 92px;
				@include _650 {
					flex-direction: column;
					gap: 10px;
				}
				ul {
					display: flex;
					gap: 27px;
					img {
						height: auto;
						width: auto;
					}
				}
			}
			.phone {
				text-align: right;
				a {
					line-height: 1.75rem;
					font-size: 1.31rem;
					text-decoration: none;
					color: black;
					font-weight: bold;
				}
				a:hover {
					text-decoration: underline;
				}
				p {
					font-size: 0.625rem;
					line-height: 1rem;
					color: #00000066;
				}
			}
		}
	}
}
.contacts {
	display: flex;
	align-self: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 44px 16px 136px;
	width: 100%;

	h1 {
		font-weight: 500;
		font-size: 1.25rem;
		line-height: 1.5rem;
		color: #717272;
	}
	.heading {
		width: 1104px;
		margin-top: 43px;
		align-self: center;
		@include _1200 {
			width: 100%;
		}
		@include _744 {
			align-self: center;
			text-align: center;
		}
	}
	.contactsWrapper {
		width: 1104px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 33px;
		@include _1200 {
			width: 100%;
		}
		@include _650 {
			flex-direction: column;
			gap: 10px;
			text-align: center;
			align-items: center;
		}
	}
	.layoutContactsWrapper {
		display: flex;
		width: 1104px;
		flex-wrap: wrap;
		gap: 33px;
		justify-content: space-between;
		@include _1200 {
			width: 100%;
		}
		@include _650 {
			flex-direction: column;
			gap: 10px;
			text-align: center;
			align-items: center;
		}
	}
	.cardsWrapper {
		display: flex;
		flex-direction: column;
	}
}
.cardContainer {
	display: flex;
	flex-direction: column;
	max-width: 150px;
	h3 {
		font-size: 1.25rem;
		line-height: 1.5rem;
		color: #717272;
	}
	h4 {
		font-size: 0.8rem;
		line-height: 1.75rem;
		color: #717272;
	}
	h5 {
		font-size: 0.8rem;
		line-height: 1.5rem;
		color: #000000cc;
	}
	a {
		font-size: 0.8rem;
		color: #00000066;
		font-weight: 400;
		text-decoration: none;
	}
	.contactInfo {
		color: var(--link);
	}
	.free {
		font-size: 0.8rem;
		line-height: 1.45rem;
		color: #0000008a;
	}
	a:hover {
		text-decoration: underline;
	}
}
.address {
	font-size: 0.8rem;
	line-height: 1.45rem;
	color: #000000cc;
}
