@import 'src/assets/styles/helpers/_mixin';
.banner {
	margin-top: 76px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include _1250 {
		padding: 40px;
	}

	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 500;
		text-align: center;
	}
	.bannerContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 92px;
		width: 100%;
		@include _1024 {
			flex-direction: column;
			gap: 40px;
		}
		p {
			font-size: 2rem;
			line-height: 2.125rem;
			min-width: 600px;
			@include _744 {
				min-width: auto;
			}
		}
		button {
			margin-top: 45px;
			width: 360px;
		}
		img {
			max-width: 505px;
			height: auto;
		}
	}
}

.payment {
	margin-top: 94px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 500;
		text-align: center;
	}
	.bannerCardsContainer {
		margin-top: 76px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 44px;
		> div {
			width: 241px;
			height: 181px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			border: 1px solid #000000;
			background: white;
			border-radius: 24px;
			position: relative;
			@include _480 {
				width: 250px;
				height: 200px !important;
			}
			.text {
				padding-top: 38px;
				max-width: 170px;
				font-size: 1.125rem;
				line-height: 1.5rem;
			}
		}
		> div:nth-child(n-3) {
			margin-top: 25px;
		}
		.index {
			background: var(--accent);
			color: white;
			position: absolute;
			top: -30px;
			border-radius: 18px;
			height: 56px;
			width: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.5rem;
			font-weight: 500;
		}
	}
}
.levels {
	margin-top: 94px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 30px;
	@include _1250 {
		padding: 40px;
	}
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 500;
		text-align: center;
	}
	.levelsContainer {
		display: flex;
		flex-direction: column;
		gap: 30px;
		@include _1250 {
			flex-direction: row;
			justify-content: space-between;
		}
		@include _744 {
			flex-wrap: wrap;
		}
	}
	.LevelItem {
		background: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: auto;
		align-self: flex-start;
		height: 162px;
		padding-left: 77px;
		border-radius: 24px;
		@include _1250 {
			padding-left: 0;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			gap: 70px;
			padding-bottom: 40px;
		}
		div {
			background: #7659a1;
			margin-right: 50px;
			width: 217px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			border-radius: 20px;
			padding: 24px 13px;
			p {
				line-height: 1rem;
				text-align: center;
			}
			@include _1250 {
				margin-right: 0;
			}
		}
		div:first-child {
			background: white;
			margin-right: 84px;
			width: 152px;
			text-align: center;
			color: black;
			flex-direction: column;
			h3 {
				font-size: 1.125rem;
				font-weight: 500;
				font-family: var(--accent-font-family);
			}
			p {
				font-family: var(--accent-font-family);
			}
			@include _1250 {
				margin-right: 0;
			}
		}
	}
}
.potential {
	margin-top: 94px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@include _1250 {
		padding: 40px;
	}
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		font-weight: 500;
		text-align: center;
	}
	.potentialContainer {
		margin-top: 92px;
		display: flex;
		justify-content: space-between;
		gap: 94px;
		align-items: center;
		@include _744 {
			flex-direction: column;
		}

		> div {
			> p {
				font-size: 1.875rem;
				line-height: 2.5rem;
				text-align: center;
			}
		}
	}
	.switches {
		display: flex;
		flex-direction: column;
		gap: 20px;
		@include _744 {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}
		div {
			width: 360px;
			height: 60px;
			border-radius: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			background: white;
			box-shadow: 1px 4px 4px #00000040;
			cursor: pointer;
			color: black;
			transition: 0.5s;
			font-size: 1.125rem;
			line-height: 1.06rem;
			@include _744 {
				width: 250px;
				max-width: 100%;
			}
		}
	}
	.active {
		background: #7659a1 !important;
		color: white !important;
	}
}
.info {
	margin-top: 85px;
	background: white;
	display: flex;
	border-radius: 24px;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 6.65px 5.32px #0000000a;
	@include _650 {
		flex-direction: column-reverse;
		padding-bottom: 15px;
	}
	form {
		padding-top: 75px;
		padding-bottom: 45px;
		margin-left: 140px;
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 534px;
		@include _744 {
			margin: 20px;
		}
		h1 {
			font-size: 1.37rem;
			font-weight: 500;
			line-height: 1.75rem;
			margin-bottom: 27px;
		}
		label {
			width: 100%;
			input {
				height: 54px;
				width: 100%;
				border: none;
				background: #ecf1f7;
				border-radius: 4px;
				padding: 15px 16px;
				font-size: 0.9rem;
			}
		}
		div {
			display: flex;
			gap: 22px;
		}
		.border {
			margin-top: 46px;
			width: 100%;
			height: 1px;
			background: #e7e8ea;
		}
		button {
			margin-top: 44px;
			align-self: flex-end;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
}
.faq {
	> h1 {
		margin-top: 100px;
		text-align: center;
	}
	.accordionContainer {
		margin-top: 48px;
		background: white;
		border-radius: 24px;
		h3 {
			color: #333333;
			font-size: 1.375rem;
			font-weight: 500;
			margin: 0;
		}
		div {
			box-shadow: none;
			border-top-left-radius: 24px;
			border-top-right-radius: 24px;
		}
		div:last-child {
			border-bottom-left-radius: 24px;
			border-bottom-right-radius: 24px;
		}
	}
}
.question {
	display: flex;
	justify-content: space-between;
	width: 912px;
	align-self: center;
	h3 {
		padding-bottom: 13px;
		padding-top: 17px;
		font-weight: 500;
		font-size: 1.375rem;
		line-height: 1.75rem;
	}
}
.feedback {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 150px;
	h1 {
		font-size: 2.625rem;
		line-height: 3rem;
		text-align: center;
		font-weight: 700;
	}
	.feedbackContainer {
		margin-top: 70px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 42px;
	}
	.feedbackItem {
		width: 340px;
		background: white;
		border-radius: 21px;
		padding: 24px;
		h3 {
			font-size: 1.06rem;
			color: black;
			line-height: 1.75rem;
			font-weight: 500;
		}

		p:first-child {
			color: #00000066;
			font-size: 0.875rem;
			line-height: 1.5rem;
		}
		p:last-child {
			margin-top: 19px;
			color: #000000cc;
			font-size: 0.75rem;
			line-height: 1.25rem;
		}
		div {
			margin-top: 11px;
			display: flex;
			gap: 4px;
		}
	}
}
