@import "src/assets/styles/helpers/_mixin";
.burger {
	width: 42px;
	height: 42px;
	position: relative;
	z-index: 7;
	overflow: hidden;
	cursor: pointer;
	background: #333238;
	display: none;
	border-radius: 8px;
	@include _900 {
		display: block;
	}
	span {
		width: 30px;
		height: 2.5px;
		border-radius: 2px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: white;
		transition: all 0.3s linear;
		&:nth-of-type(2) {
			top: calc(50% - 8px);
		}

		&:nth-of-type(3) {
			top: calc(50% + 8px);
		}
	}

	&.active {
		background: #1d1c22;
		span {
			background-color: white;

			&:nth-of-type(1) {
				width: 0;
			}

			&:nth-of-type(2) {
				top: 50%;
				transform: translate(-50%, 0%) rotate(45deg);
			}

			&:nth-of-type(3) {
				top: 50%;
				transform: translate(-50%, 0%) rotate(-45deg);
			}
		}
	}
}
