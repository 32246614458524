.search {
	position: relative;
	width: 100%;
	input {
		width: 100%;
		height: 53px;
		background: #eaecee;
		border: none;
		padding: 20px;
		border-radius: 10px;
		font-size: 1.1rem;
		display: flex;
		align-items: center;
	}
	input::-moz-placeholder {
		color: #bdc4cacc;
	}
	input::-webkit-input-placeholder {
		color: #bdc4cacc;
	}
	img {
		position: absolute;
		right: 20px;
		top: 18px;
		width: 18px;
		height: 18px;
		opacity: 0.5;
	}
}
