@import 'src/assets/styles/helpers/_mixin';
.banner {
	margin-top: 76px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	max-width: 100%;
	gap: 46px;
	> div {
		h1 {
			font-weight: 500;
			font-size: 2.6875rem;
			line-height: 3rem;
		}
		p {
			margin-top: 24px;
			font-size: 0.875rem;
			line-height: 1.5rem;
		}
	}
	img {
		max-width: 100%;
	}
}
.benefits {
	margin-top: 48px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 48px;

	.benefitsCard {
		display: flex;
		flex-direction: column;
		gap: 44px;
		background: #ffffff;
		border-radius: 24px;
		max-width: 336px;
		padding: 32px;
		@include _650 {
			width: 336px;
		}
		h3 {
			font-size: 1.37rem;
			font-weight: 500;
			line-height: 1.75rem;
		}
		p {
			margin-top: 12px;
			font-size: 0.875rem;
			line-height: 1.5rem;
		}
		img {
			width: 28px;
			height: 28px;
		}
	}
}
.getJobContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 100px;
	h1 {
		font-weight: 500;
		font-size: 2.6875rem;
		line-height: 3rem;
	}
}
.getJob {
	margin-top: 76px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 48px;
	text-align: center;
	> div {
		width: 240px;
		height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #000000;
		background: white;
		border-radius: 24px;
		position: relative;
		@include _480 {
			width: 250px;
			height: 200px !important;
		}
		.text {
			padding-top: 56px;
			max-width: 275px;
			font-size: 0.95rem;
			line-height: 1.5rem;
		}
	}
	> div:nth-child(n-3) {
		margin-top: 25px;
	}
	.index {
		background: var(--accent);
		color: white;
		position: absolute;
		top: -30px;
		border-radius: 18px;
		height: 56px;
		width: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.5rem;
		font-weight: 500;
	}
}
.availableJobs {
	margin-top: 76px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 56px;
	h1 {
		font-weight: 500;
		font-size: 2.6875rem;
		line-height: 3rem;
	}
	.job:hover {
		transform: scale(1.01);
	}
	.job {
		transition: 0.3s;
		border-radius: 24px;
		width: 100%;
		background: white;
		display: flex;
		justify-content: space-between;
		padding: 32px;
		@include _650 {
			flex-direction: column-reverse;
		}
		.jobContent {
			max-width: 661px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include _650 {
				margin-top: 24px;
			}
			h3 {
				font-size: 1.37rem;
				font-weight: 500;
				line-height: 1.75rem;
			}
			p {
				margin-top: 12px;
				font-size: 0.875rem;
				line-height: 1.5rem;
			}
			.jobConditions {
				margin-top: 42px;
				display: flex;
				gap: 5px;
				div {
					font-size: 0.8rem;
					background: #0000000f;
					border-radius: 6px;
					padding: 2px 8px;
				}
			}
		}
		.salary {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
			min-width: 200px;
			@include _650 {
				flex-direction: row;
				align-items: center;
			}
			h3 {
				font-size: 1.37rem;
				font-weight: 500;
				line-height: 1.75rem;
			}
			> button {
				background: #ecf1f7;
				outline-color: #ecf1f7;
				color: var(--link);
				width: 145px;
				height: 44px;
			}
		}
	}
	> button {
		width: 177px;
		height: 28px;
		margin-top: 16px;
		font-size: 1.125rem;
		line-height: 1.75rem;
		padding: 0;
		border-radius: 10px;
	}
}
.info {
	margin-top: 85px;
	background: white;
	display: flex;
	border-radius: 24px;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 6.65px 5.32px #0000000a;
	@include _650 {
		flex-direction: column-reverse;
		padding-bottom: 15px;
	}
	form {
		margin: 75px 0 45px 140px;
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 534px;
		@include _744 {
			margin: 20px;
		}

		h1 {
			font-size: 1.37rem;
			font-weight: 500;
			line-height: 1.75rem;
			margin-bottom: 27px;
		}
		label {
			width: 100%;
			input {
				height: 54px;
				width: 100%;
				border: none;
				background: #ecf1f7;
				border-radius: 4px;
				padding: 15px 16px;
				font-size: 0.9rem;
			}
		}
		div {
			display: flex;
			gap: 22px;
		}
		.border {
			margin-top: 46px;
			width: 100%;
			height: 1px;
			background: #e7e8ea;
		}
		button {
			margin-top: 44px;
			align-self: flex-end;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
}
.conditions {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		text-align: center;
		font-weight: 500;
		font-size: 2.6875rem;
		line-height: 3rem;
	}
	.conditionsContainer {
		margin-top: 48px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 48px;
	}
	.conditionsCard {
		display: flex;
		flex-direction: column;
		gap: 44px;
		background: #eaecee;
		border-radius: 24px;
		max-width: 336px;
		padding: 36px;
		h3 {
			font-size: 1.37rem;
			font-weight: 500;
			line-height: 1.75rem;
		}
		p {
			margin-top: 12px;
			font-size: 0.875rem;
			line-height: 1.5rem;
		}
		img {
			width: 36px;
			height: 36px;
			margin-top: -10px;
		}
	}
}
