@import '../helpers/mixin';
@import '../helpers/variables';

html {
	scroll-behavior: smooth;
}

body {
	background: #f6f7f8;
	font-family: var(--body-font-family);
	font-size: var(--body-font-size);
	line-height: var(--body-line-height);
	font-weight: var(--body-font-weight);
	color: var(--black);
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	overflow-x: hidden;
}

h1 {
	font-size: 2.625rem;
	line-height: 3rem;
	font-weight: 500;
	color: #000000cc;
	@include _480 {
		font-size: $title-mob;
		line-height: $sub-title-height;
	}
}

.titleHeader {
	font-size: $header;
	line-height: $header-height;
	font-weight: 600;
	margin-bottom: 3rem;

	@include _480 {
		font-size: $header-mob;
		line-height: $header-mob-height;
	}
}
