.cards {
	margin-top: 73px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 46px;
	h1 {
		font-size: 2.6875rem;
		line-height: 3rem;
		font-weight: 700;
	}
	p {
		font-size: 0.93rem;
		line-height: 1.5rem;
		margin-top: 25px;
	}
}
.cadrsWrapper {
	margin-top: 42px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	> button {
		margin-top: 64px;
		padding: 0 14px;
		width: 177px;
		height: 28px;
		font-size: 1.125rem;
		line-height: 1.75rem;
		border-radius: 10px;
	}
	.cardContainer {
		padding: 0 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 43px;
		.card {
			background: #eaecee;
			width: 240px;
			height: 356px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 19px 24px;
			border-radius: 24px;
			img {
				height: 120px;
				width: auto;
			}
			h3 {
				width: 100%;
				text-align: left;
				font-size: 1.375rem;
				line-height: 1.75rem;
				font-weight: 500;
			}
		}
	}
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: rgba(0, 0, 0, 0.3);
	z-index: 6;
	.modalHead {
		padding: 11px 41px;
		color: white;
		width: 618px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #7659a1;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		h1 {
			font-size: 1.875rem;
			font-weight: 400;
		}
	}
	.modalBody {
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		padding: 28px 86px 48px;
		background: white;
		width: 618px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p {
			margin-top: 20px;
			font-weight: 400;
			font-size: 1rem;
		}

		.modalForm {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 20px;
			> button:last-child {
				align-self: flex-end !important;
			}
			> div {
			}
		}

		input {
			margin-top: 20px;
			height: 38px;
			border: 1px solid #eaecee;
			width: 100%;
			padding-left: 25px;
			border-radius: 5px;
			font-size: 1rem;
		}
		.inputContainer {
			display: flex;
			gap: 20px;
		}
		.input::-moz-placeholder {
			color: #eaecee;
		}
		.input::-webkit-input-placeholder {
			color: #eaecee;
		}
	}
}
.cross {
	background: transparent;
	border: none;
	cursor: pointer;
	width: 40px;
	height: 30px;
	div {
		background: white;
		width: 100%;
		height: 2px;
	}
	> div {
		margin-top: -2px;
		transform: rotateZ(-45deg);
	}
	div:last-child {
		transform: rotateZ(45deg);
	}
}
.serviceFormContainer {
	max-width: 754px;
	align-self: flex-end;
}
.inputShadow {
	max-width: 753px;
	box-shadow: 0 4px 4px #00000040;
}
