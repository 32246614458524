@import 'src/assets/styles/helpers/_mixin';
.banner {
	margin-top: 118px;
	width: 100%;
	background: white;
	border-radius: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 84px;
	padding-right: 20px;
	@include _480 {
		flex-direction: column;
		padding: 20px 0;
		text-align: center;
	}
	div {
		max-width: 281px;
		h1 {
			font-weight: 500;
			font-size: 2.625rem;
		}
		p {
			color: black;
			margin-top: 10px;
			font-size: 0.875rem;
			line-height: 1.5rem;
		}
	}
	img {
		max-height: 383px;
		max-width: 470px;
		width: 100%;
		border-radius: 24px;
		@include _744 {
			max-width: 60%;
		}
		@include _480 {
			align-self: center;
		}
	}
}
.individual {
	margin-top: 96px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
	h1 {
		font-weight: 500;
		font-size: 2.625rem;
	}
	> div {
		display: flex;
		width: 100%;
		gap: 48px;
		flex-wrap: wrap;
		> div {
			background: white;
			flex: 1;
			padding: 24px 32px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			border-radius: 24px;
			img {
				width: 52px;
				height: 52px;
			}
			h3 {
				margin-top: 24px;
				font-size: 1.375rem;
				line-height: 1.75rem;
				font-weight: 500;
			}
		}
	}
	a {
		text-decoration: none;
		color: var(--link);
		font-size: 0.93rem;
		line-height: 1.5rem;
	}
	a:hover {
		text-decoration: underline;
	}
	p {
		font-size: 0.875rem;
		line-height: 1.5rem;
		color: #000000cc;
	}
	span {
		color: #0000008a;
	}
}
.buisness {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
	h1 {
		font-weight: 500;
		font-size: 2.625rem;
	}
	> div {
		width: 100%;
		display: flex;
		gap: 48px;
		@include _744 {
			flex-wrap: wrap;
			justify-content: center;
		}
		> div:last-child {
			padding-top: 32px;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;
			max-width: 300px;
			div:last-child {
				margin-top: 42px;
			}
		}
	}
	.imageContainer {
		background: #eaecee;
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 720px;
		border-radius: 24px;
		img {
			width: 100%;
		}
	}
	a {
		text-decoration: none;
		color: var(--link);
		font-size: 0.93rem;
		line-height: 1.5rem;
	}
	a:hover {
		text-decoration: underline;
	}
	p {
		font-size: 0.875rem;
		line-height: 1.5rem;
		color: #000000cc;
	}
	span {
		color: #0000008a;
	}
}
.partners {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;

	h1 {
		font-weight: 500;
		font-size: 2.625rem;
	}

	a {
		text-decoration: none;
		color: var(--link);
		font-size: 0.93rem;
		line-height: 1.5rem;
	}
	a:hover {
		text-decoration: underline;
	}
	p {
		font-size: 0.875rem;
		line-height: 1.5rem;
		color: #000000cc;
	}
	span {
		color: #0000008a;
	}
	> div {
		display: flex;
		gap: 48px;
		flex-wrap: wrap;
		justify-content: center;
		div:first-child {
			img {
				width: 52px;
				height: 52px;
			}

			h3 {
				margin-top: 8px;
			}
			> div {
				margin-top: 20px;
			}
		}
		> div {
			width: 336px;
			img {
				width: 40px;
				height: 40px;
			}
			h3 {
				margin-top: 16px;
				font-size: 1.375rem;
				line-height: 1.75rem;
				font-weight: 500;
			}
			padding: 32px 24px;
			background: white;
			border-radius: 24px;
			display: flex;
			flex-direction: column;
			> div {
				margin-top: 22px;
				display: flex;
				flex-direction: column;
				gap: 6px;
			}
		}
	}
}
.map {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;

	h1 {
		font-weight: 500;
		font-size: 2.625rem;
	}

	> div {
		display: flex;
		justify-content: space-between;
		gap: 48px;

		@include _1015 {
			padding: 20px;
		}
		@include _744 {
			flex-wrap: wrap;
			flex-direction: column-reverse;
		}
		> div {
			margin-top: 32px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			a {
				text-decoration: none;
				color: var(--link);
				font-size: 0.93rem;
				line-height: 1.5rem;
			}
			a:hover {
				text-decoration: underline;
			}
			p {
				font-size: 0.875rem;
				line-height: 1.5rem;
				color: #000000cc;
			}
			img {
				margin-top: 12px;
				width: 200px;
				height: 45px;
			}
		}
		> img {
			border-radius: 24px;
			width: 100%;
		}
	}
}
